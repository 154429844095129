/* 
    Vue.mixin() 混合配置 是一个复用组件配置的方法
    当项目内多个组件需要用到同一种逻辑或者代码时，可配置mixin对象并导出，在main.js中导入
    并通过Vue.mixin(mixin)使所有组件拥有该配置
*/
export default {
    // 导出的对象内部配置写法同组件配置
    /*
        因为权限数据在用户登陆的时候获取并存储到中央共享仓库的state中
        可通过计算属性使用state中的数据
    */ 
   computed:{
    mixRights(){
        // 获取用户当前访问页面的路由地址，用于之后与state中的权限数据进行判断
        let path = this.$route.path;
        // 获取中央仓库中的权限数据
        let rights = this.$store.state.userRights;
        // 获取该用户在当前页面的权限总值，用于页面中使用进行按钮权限判断
        let [theRight] = rights.filter(ele => ele.right_path === path);
        return theRight.right_function;
    }
   }
}