
export default [
    {
        path: 'manager/class',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/ClassManage.vue')
    },
    {
        path: 'manager/lesson',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/LessonManage.vue')
    },
    {
        path: 'manager/options',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/ManagerOptions.vue')
    },
    {
        path: 'manager/select',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/SelectManage.vue')
    },
    {
        path: 'manager/series',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/SeriesManage.vue')
    },
    {
        path: 'manager/specialty',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/SpecialtyManage.vue')
    },
    {
        path: 'manager/student',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/StudentManage.vue')
    },
    {
        path: 'manager/teacher',
        component: () => import(/*webpackChunkName:'group-manager'*/'@/views/manager/TeacherManage.vue')
    },
]