import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        // 用户权限数组
        userRights:[]
    },
    getters: {
    },
    mutations: {
        // 突变方法，满足Vue项目中单向数据流的规则，不直接在页面中修改数据
        setUserRights(state,newVal){
            state.userRights = newVal;
        }
    },
    actions: {
    },
    modules: {
    },
    // 为vuex加载插件
    plugins:[ 
      // 启动突变日志
      Vuex.createLogger() 
    ]
})