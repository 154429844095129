// 引入axios模块
import axios from 'axios'
// 引入 element-ui的Message插件
import {Message} from 'element-ui'


// 创建副本
const axCopy = axios.create({
    // baseURL:'http://127.0.0.1:3000',
    /*
        配置了全局环境之后如何使用？
            通过 process.env. 来调用环境变量
    */
   baseURL:process.env.VUE_APP_API_BASE_URL
})

// 拦截器定义-请求拦截器
axCopy.interceptors.request.use(
    function(config){
        // 检测是否为公开请求
        if(!config.isPublic){
            // 没有该属性为非公开
            config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        }
        return config;
    },
    function(error){
        return Promise.reject(error);
    }
)

// 拦截器定义-响应拦截器
axCopy.interceptors.response.use(
    function({data}){
        // 因此项目只从一个服务器获取数据结果且数据格式统一，所以对于结果采取统一处理
        if(data.status>=400){
            Message.error(data.msg);
            return Promise.reject(data)
        }
        // 数据正确时仅返回渲染页面所需数据
        return data.data;
    },
    function(error){
        // 使用 element-ui 提示框插件来做失败消息提示
        Message.error('请求失败，请检查配置或服务器');
        return Promise.reject(error);
    }
)

// 导出
export default axCopy