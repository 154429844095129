export default [
    {
        path:'teacher/list',
        component:()=>import(/*webpackChunkName:'group-teacher'*/'@/views/teacher/CourseList.vue')
    },
    {
        path:'teacher/inquire',
        component:()=>import(/*webpackChunkName:'group-teacher'*/'@/views/teacher/CourseInquire.vue')
    },
    {
        path:'teacher/entry',
        component:()=>import(/*webpackChunkName:'group-teacher'*/'@/views/teacher/ScoreEntry.vue')
    },
]