import Vue from 'vue'
// 导入核心模块
import VueRouter from 'vue-router'
import Manager from '@/router/manager/routers'
import Teacher from '@/router/teacher/routers'
import Home from '@/router/home/router'
import { Message } from 'element-ui'
import store from '@/store/index'
import {getAsideMennue} from '@/api/home/asideMennue'

// 通过实例调用方法使用
Vue.use(VueRouter)

// 路由表定义(一级导航)
/*
    路由表配置有两种方式，一种是先通过 impor from 导入组件，再通过 routes数组 component回调方法导入
    此方式由于组件导入和路由表配置分开书写，当组件数量过多时不方便阅读及维护；另一种方式为直接再路由表中
    通过component方法返回 impor()方法导入，注意导入时路径最好是 @/ 定义为src文件，就可以不用考虑路由表
    转移时导入组件的路径问题
*/
const routes = [
  {
    path: '/',
    component: ()=>import('@/views/login/LoginPage.vue'),
    // 对公开页面(不需要登录的页面)添加元对象
    meta:{
      isPublic:true
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home/HomeNav.vue'),
    // 子组件定义(二级导航)，以免入口文件内部代码复杂难以阅读，所以做了拆分
    children:[...Manager,...Teacher,...Home],
    /*
        需注意：若是父组件在子组件中定义了默认组件(默认页面)，则meta元对象应配置在子组件中的默认组件中
        (因为默认地址不再是父组件的地址)，所以此处如果需要对此父组件增加meta元对象应添加在子组件的
        HomeManage.vue中
    */
  },
  // {
  //   path:'/teacher/home',
  //   component:()=>import('@/views/teacher/HomePage.vue'),
  //   // 子组件定义
  //   children:Teacher
  // },
  // 对未存在的路径同一重定向到 登录页面 此处路由的定向顺序为从上到下的顺序，所以 404处理应该写在所有组件之后
  {
    path:'/*',
    redirect:'/',
    meta:{
      isPublic:true
    }
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

/* 
    全局路由守卫 由路由对象调用，对所有组件生效
      1. 全局进入 调用 beforeEach() 当前组件被切换，下一个组件渲染前触发(实例创建完毕，容器挂载前)
      2. 全局解析 调用 beforeSolve() 下一个组件渲染后触发(容器挂载后)
      3. 全局离开 调用 arterEach() 当前组件被切换时，如果需要缓存则先触发此方法再执行缓存，如果不缓存
         则先触发此方法，再执行 destroied()钩子函数
*/

// 简单的登录验证全局进入守卫：
router.beforeEach(async function(to,from,next){ // 传入回调方法参数，该回调接收三个参数 to from next
  // to:下一个要显示的路由
  // from:被切换的路由
  // next:路由控制方法，决定是否放行
  
  /*
    to和from参数都是路由信息对象副本(内置参数与信息对象差不多，对其进行修改不会影响信息对象)
    且额外含有meta元对象属性，该属性在定义路由时自主设置，在登陆验证的逻辑判断中可发挥用处
  */

  // 公开页面(login、404)直接放行访问
  if(to.meta.isPublic){
    next();
    return
  }
  
  // 非公开页面需要授权检测
  if(localStorage.getItem('token')!==null){
    // 已进行登录
    /*
     用户权限校验
        1. 此处思路为，用户在登陆时，会获取数据用于渲染导航栏，导航栏内容就是该用户可以访问的路由，所以需要对用户将要访问的路由
           做判断，是否存在于用户导航栏中，如果存在则符合该用户的权限；不存在则不符合权限
        2.  
    */
    // 将中央仓库中的权限数据作为判断条件
    if(store.state.userRights.length === 0){ // 数据为空
      // 就先发送请求获取数据，再判断
      // 调用api中的方法，因此方法基于Promise封装所以需将其转换为类同步代码
      let userRights = await getAsideMennue();
      store.commit('setUserRights',userRights); // 修改中央仓库数据
      // 任何组件需要访问都要先执行路由守卫，所以组件中可免去获取数据步骤，使用仓库数据用以渲染页面
    }
    // 执行至此则权限数据必定不为空数组，进行权限判断
    let rightFlag = store.state.userRights.some(ele=>ele.right_path == to.path);
    if(rightFlag){
      // 该用户拥有访问此路由的权限直接放行
      next();
    }else{
      // 没有此权限，做个提示并重定向到该用户首页
      Message.info('无权限访问该页面');
      next(store.state.userRights[0].right_path);
    }
    
  }else{
    Message.error('请先登录');
    from.path == '/' ? next() : next('/');
  }

 
})
export default router
