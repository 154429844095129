import "element-ui/lib/theme-chalk/index.css"
import '@/assets/CSS/main.css'

import Vue from 'vue'
// 引入router 组件路由
import router from './router'
// store中央仓库
import store from './store'
// Vue.mixin() 配置混合方法
import mixin from '@/mixin/index'
import ElementUI from "element-ui"
import App from './App.vue'



Vue.config.productionTip = false;
// 切记通过Vue.use()使用模块
Vue.use(ElementUI);
// 配置混合专用方法
Vue.mixin(mixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
